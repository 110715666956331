$color-footer-bg:#1f2024;



$xl-width:100;
$xxl-width:100;

// info-box 350px


$breakpoints: (
        "sp": "screen and (max-width: 640px)",

        'ss': 'screen and (max-width: 363px)',
        's': 'screen and (max-width: 640px)', //mobile
        'l': 'screen and (min-width: 641px)', // desktopo

        'll': 'screen and (min-width: 1540px)',

        'l1539': 'screen and (min-width: 641px) and (max-width:1539px)',

        'l1190': 'screen and (min-width: 641px) and (max-width:1190px)' //less than desktop min width
) !default;


$color-text: #656565;
$color-text-hover: #333;
$color-gray: #6E6E6E;

$color-text-hover-blue: #3865E0;

$link-icon-border: solid #656565;
$global-nav-link-icon-border: solid #999;

//$bold-font-family: "SST W20 Bold", "SST W55 Bold", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "MS Pゴシック", Osaka, sans-serif;
//$regular-font-family: "SST W20 Roman", "SST W55 Regular", "Yu Gothic Medium";


// ローカルナビで使用しているリンクの矢印
@mixin link-arw(){
  content: "";
  display: inline-block;
  width: 7px;
  height: 7px;
  border: $link-icon-border;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  margin: 0 0 1px 0.64rem;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform, margin;
}


@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}


// responsive size (vw)
@function rpvw($size){
  @return ($size / strip-unit(375) * 100) + vw;
}

@function rpvwf($size){
  @return floor($size / strip-unit(375) * 100) + vw;
}

@function rpp($size){
  @return floor($size / strip-unit(375) * 100%);
}




@function rem($size){
  @return $size / 16 + rem;
}



$base-font-size: 16;
//$show-rem: false;
$show-rem: true;

@function re($px){
  @if $show-rem{
    @return $px / $base-font-size + rem;
  } @else {
    @return $px  + px;
  }
}


@mixin img-hover(){
  transition: transform 300ms ease-out;

  &:hover{
    transform:scale(1.1);
  }
}

@mixin link-blue(){
  color:#3865E0;
  //&:hover{
  //    color:#000000;
  //}
}

@mixin hidden(){
  display:none;
}

@mixin text-hover(){
  transition: opacity 300ms ease-out;

  &:hover{
    opacity:0.6;
  }
}

@mixin objectFitCover(){
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@mixin focus(){
  &:focus,
  &:focus-visible{
    outline: 2px solid black;
  }
}


@mixin vh(){
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}



//@mixin mq($breakpoint) {
//  @media #{map-get($breakpoints, $breakpoint)} {
//    @content;
//  }
//}
@mixin mq($breakpoint: m) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}