$bold-font-family: "SST W20 Bold", Arial, "Helvetica Neue", sans-serif;
$regular-font-family: "SST W20 Roman", Arial, "Helvetica Neue", sans-serif;


@mixin bold-font(){
  font-weight: bold;
  font-family: $bold-font-family;
}

@mixin regular-font(){
  font-family: $regular-font-family;
}